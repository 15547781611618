<template>
	<div>
		<div style="margin: 15px 0 10px 0;">
			<a-form-model layout="inline" :model="search" class="search-form">
				<a-form-model-item label="起始日期:">
					<a-date-picker v-model="search.startTime" placeholder="选择日期时间" :format="dateFormat" :locale="datePickerLocale" style="width: 100%;" @change="onChange()"/>
				</a-form-model-item>
				<a-form-model-item label="结束日期:">
					<a-date-picker v-model="search.endTime" placeholder="选择日期时间" :format="dateFormat" :locale="datePickerLocale" style="width: 100%;" @change="onChange()"/>
				</a-form-model-item>
				<a-form-model-item label="设备别名:" editable size="mini">
					<a-select v-model="search.equipmentId" filterable placeholder="请选择" style="width: 190px;">
						<a-select-option key="全部" value="-1">全部</a-select-option>
						<a-select-option v-for="item in equipmentList" :key="item.id" :value="item.id">
							{{item.name}}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item>
					<a-button type="primary" @click="searchFn">查询</a-button>
				</a-form-model-item>
				<a-form-model-item>
					<a-button type="primary" @click="exportFn">导出</a-button>
				</a-form-model-item>
			</a-form-model>
		</div>
		<div id="soc-power-chart"></div>
	</div>
</template>

<script>
	import { beautifyPowerAnalysis } from './help.js'
	import axios from 'axios';
	import moment from 'moment';
	import $ from 'jquery';
	import datePickerLocale from 'ant-design-vue/es/date-picker/locale/zh_CN';
	export default {
		data() {
			return {
				datePickerLocale: datePickerLocale,
				dateFormat: 'YYYY-MM-DD',
				search: {
					stationId: localStorage.getItem('stationId'),
					startTime: moment(),
					endTime: moment(),
					equipmentId: '-1',
					signalId: 1281
				},
				equipmentList: [],
				data: [],
				soc_power_chart: null,
				soc_power_option: null,
			}
		},
		mounted() {
			var token = localStorage.getItem("currentUser_token");
			if ( !token && typeof(token)!="undefined" && token!=0){
			   	this.$router.replace({
					path: '/'
				})
				return;
			}
			var id = localStorage.getItem('stationId');
			if ( !id && typeof(id)!="undefined" && id!=0){
			   	this.$router.replace({
					path: '/distribution'
				})
			}
			var this_ = this;
			this_.init("soc-power-chart");
			this_.getEquipmentList();
		},
		methods: {
			onChange () {
				if(typeof(this.search.startTime) === 'string'){
					this.search.startTime = this.search.startTime.split(" ")[0]
					this.search.startTime = moment(this.search.startTime);
				}
				if(typeof(this.search.endTime) === 'string'){
					this.search.endTime = this.search.endTime.split(" ")[0];
					this.search.endTime =moment(this.search.endTime);
				}
		    },
			getEquipmentList() {
				var this_ = this;
				var data = {
					typeId: 3,
					stationId: localStorage.getItem('stationId')
				}
				axios({
					method: 'post',
					url: '/emind/config/equipment/search_condition',
					data: data
				}).then(function(d) { //
					if(!d){
						this_.$message.error('登录超时，请重新登录', 2, function() {});
						return;
					}
					if(d.data.code === '0') {
						this_.equipmentList = d.data.data;
						this_.equipmentList.sort(this_.sortNum);
						if(this_.equipmentList.length > 0) {
							this_.search.equipmentId = this_.equipmentList[0].id;
							this_.reflush();
						}
					} else {
						this_.$message.error('请求出错', 2, function() {});
					}
				}).catch(function(error) {
					if(error.message != '') {
						this_.$message.error(error.message, 2, function() {});
						return;
					}
				});
			},
			sortNum(a,b){  
				if(a.typeId == 3 && b.typeId == 3){
					return (a.num-b.num) * 1; 
				} else if(a.typeId == 3){
					return -1;
				} else {
					return a.typeId - b.typeId;
				}
		   	},
			reflush() {
				var this_ = this;
				var data = {
					stationId: this.search.stationId,
					equipmentId: this.search.equipmentId,
					signalId: this.search.signalId,
				};
				if(this.search.startTime != null && this.search.endTime != null && this.search.startTime.toDate().getTime() >this.search.endTime.toDate().getTime()) {
					this.$message.error('结束时间早于起始时间，请重新设置查询时间！');
					return;
				} else {
					var dataTime = '';
					if(this_.search.startTime != null){
						dataTime = this_.search.startTime.format('YYYY-MM-DD ') + "00:00:00";
						data.startTime = dataTime;
					} else {
						data.startTime = '1970-01-01 00:00:00'
					}
					if(this_.search.endTime != null){
						dataTime = this_.search.endTime.format('YYYY-MM-DD ') + "23:59:59";
						data.endTime = dataTime;
					} else {
						if(this_.search.startTime.toDate().getTime() > new Date().getTime()){
						} else {
							data.endTime = moment().format('YYYY-MM-DD ') + "23:59:59";
						}
					}
				}
				this_.soc_power_chart.showLoading({
					text: '数据正在加载。。。',
					color: '#4cbbff',
					textColor: '#4cbbff',
					maskColor: 'rgba(61, 72, 85, 0.65)',
				});
				axios({
					method: 'post',
					url: '/emind/history/data/power_soc_chart',
					data: data
				}).then(function(d) { //
					if(!d){
						this_.$message.error('登录超时，请重新登录', 2, function() {});
						return;
					}
					var newOp = this_.soc_power_chart.getOption();
					const remoteData = d.data.data
				
					newOp.series.splice(0, newOp.series.length);
					this_.averageIncome = 0;

					beautifyPowerAnalysis(remoteData, true)
          
					newOp.series.push({
						name: 'SOC',
						type: 'line',
						animation: false,
						connectNulls: false,
						color: '#064ca1',
						data: d.data.data.map(function(x) {
							if(x.batterySystemSOC === ''){
								return x.batterySystemSOC
							} else {
								return (x.batterySystemSOC / 10).toFixed(2);
							}
						})
					});
					newOp.series.push({
						name: '功率',
						type: 'line',
						yAxisIndex: 1,
						animation: false,
						connectNulls: false,
						data: d.data.data.map(function(x) {
							if(x.isFalseValue){
								return {
									value: x.pcsPower_DC,
									itemStyle: {
										color: x.falseValueColor,
									}
								}
							} else {
								return {
									value: (x.pcsPower_DC / 10).toFixed(2),
								}
							}
						})
					});
					newOp.xAxis = [{
						nameTextStyle: {
							fontFamily: "微软雅黑",
						},
						axisLine: {
							lineStyle: {
								width: 1, //这里是为了突出显示加上的
							}
						},
						name: '',
						type: 'category',
						boundaryGap: false,
						axisLine: {
							onZero: false
						},
						data: d.data.data.map(function(x) {
							return new Date(x.recordTime).Format("yyyy-MM-dd hh:mm:ss").replace(' ', '\n');
						}),
						axisTick: {
							alignWithLabel: true
						}
					}, {
						nameTextStyle: {
							fontFamily: "微软雅黑",
						},
						axisLine: {
							lineStyle: {
								width: 1, //这里是为了突出显示加上的
							}
						},
						name: '',
						type: 'category',
						boundaryGap: false,
						axisLine: {
							onZero: false
						},
						data: d.data.data.map(function(x) {
							return new Date(x.recordTime).Format("yyyy-MM-dd hh:mm:ss").replace(' ', '\n');
						}),
						axisTick: {
							alignWithLabel: true
						}
					}]
					if(d.data.data.length < 1) {
						newOp.title = {
							textStyle: {
								fontFamily: "微软雅黑",
							},
							text: '储能单元SOC-功率跟踪分析曲线',
							subtext: '\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r暂无数据\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r暂无数据',
							x: 'center'
						};
						newOp.dataZoom = null;
					} else {
						newOp.title = {
							textStyle: {
								fontFamily: "微软雅黑",
							},
							text: '储能单元SOC-功率跟踪分析曲线',
							subtext: '',
							x: 'center'
						};
						newOp.dataZoom = [{
								show: true,
								realtime: true,
								start: 30,
								end: 70,
								xAxisIndex: [0, 1]
							},
							{
								type: 'inside',
								realtime: true,
								start: 30,
								end: 70,
								xAxisIndex: [0, 1]
							}
						];
					}
					this_.soc_power_chart.hideLoading();
					this_.soc_power_chart.setOption(newOp);
					this_.soc_power_chart.resize();
				}).catch(function(error) {
					this_.soc_power_chart.hideLoading();
					if(error.message != '') {
						this_.$message.error(error.message, 2, function() {});
						return;
					}
				});
			},
			init(id) {
				var this_ = this;
				this_.flag == 1
				this_.soc_power_option = {
					title: {
						textStyle: {
							fontFamily: "微软雅黑",
							color: '#E2E2E2'
						},
						text: 'SOC-功率曲线图',
						x: 'center'
					},
					legend: {
						textStyle: {
							fontFamily: "微软雅黑",
							color: '#7C868D'
						},
						data: ['SOC', '功率'],
						x: 'left'
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							animation: false
						},
						formatter: function(params) {
							if(params[0].value == '' && params[1].value == '' && typeof(params[0].value) == 'string'){
								return '没有数据';
							}
							var relVal = '日期：' + params[0].name + "<br/>" + params[0].seriesName + '：' + params[0].value + ((params[0].seriesName == 'SOC') ? '%' : 'kW') + "<br/>" + params[1].seriesName + '：' + params[1].value + ((params[1].seriesName == 'SOC') ? '%' : 'kW');
							return relVal;
						}
					},
					toolbox: {
						show: true,
						right: 20,
						feature: {
							dataZoom: {
								yAxisIndex: 'none'
							},
							restore: {},
							saveAsImage: {}
						}
					},
					axisPointer: {
						link: {
							xAxisIndex: 'all'
						}
					},
					grid: [{
						left: 50,
						right: 50,
						top: 50,
						height: 285
					}, {
						left: 50,
						right: 50,
						top: '50%',
						height: 290
					}],
					xAxis: [{
							nameTextStyle: {
								color: '#fff',
								fontFamily: "微软雅黑",
							},
							splitLine: {
								lineStyle: {
									color: '#7C868D',
								}
							},
							axisLine: {
								onZero: false,
								lineStyle: {
									color: '#7C868D',
								}
							},
							name: '时间',
							type: 'category',
							boundaryGap: false,
							data: this_.data.map(function(x) {
								return new Date(x.recordTime.toString()).Format("yyyy-MM-dd hh:mm:ss").replace(' ', '\n');

							}),
							axisTick: {
								alignWithLabel: true
							}
						},
						{
							gridIndex: 1,
							nameTextStyle: {
								fontFamily: "微软雅黑",
							},
							splitLine: {
								lineStyle: {
									color: '#7C868D',
								}
							},
							axisLine: {
								onZero: false,
								lineStyle: {
									color: '#7C868D',
								}
							},
							name: '时间',
							type: 'category',
							boundaryGap: false,
							position: 'top',
							data: this_.data.map(function(x) {
								return new Date(x.recordTime.toString()).Format("yyyy-MM-dd hh:mm:ss").replace(' ', '\n');
							}),
							axisTick: {
								alignWithLabel: true
							}
						}
					],
					yAxis: [{
							min: 0,
							max: 100,
							nameTextStyle: {
								fontFamily: "微软雅黑",
								color: '#7C868D',
							},
							axisLine: {
								show: false,
								lineStyle: {
									color: '#7C868D',
								}
							},
							splitLine: {
								lineStyle: {
									color: '#7C868D',
								}
							},
							name: 'SOC(%)',
							type: 'value'
						},
						{
							nameTextStyle: {
								fontFamily: "微软雅黑",
							},
							axisLine: {
								show: false,
								lineStyle: {
									color: '#7C868D',
								}
							},
							splitLine: {
								lineStyle: {
									color: '#7C868D',
								}
							},
							gridIndex: 1,
							name: '功率(kW)',
							type: 'value',
							nameLocation: 'start'
						}
					],
					series: [{
							name: 'SOC',
							type: 'line',
							connectNulls: false,
							animation: true,
							color: '#064ca1',
							lineStyle: {
								width: 1
							},
							data: this_.data.map(function(x) {
								return x.soc;
							}),
							itemStyle: {
								color: '#1890FF'
							},
							areaStyle: {
								color: new this_.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
									offset: 0,
									color: 'rgba(24, 144, 255, 0.5)'
								}, {
									offset: 0.5,
									color: 'rgba(24, 144, 255, 0.2)',
								}, {
									offset: 1,
									color: 'rgba(24, 144, 255, 0.1)',
								}])
							},
						},
						{
							name: '功率',
							type: 'line',
							connectNulls: false,
							xAxisIndex: 1,
							yAxisIndex: 1,
							animation: true,
							data: this_.data.map(function(x) {
								return x.powerDC / 10;
							}),
							itemStyle: {
								color: '#F26813'
							},
							areaStyle: {
								color: new this_.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
									offset: 0,
									color: 'rgba(242, 104, 19, 0.5)',
								}, {
									offset: 0.5,
									color: 'rgba(242, 104, 19, 0.2)',
								}, {
									offset: 1,
									color: 'rgba(242, 104, 19, 0.1)',
								}])
							},
						}
					]
				};
				this_.soc_power_chart = this_.$echarts.init(document.getElementById(id));
				this_.soc_power_chart.setOption(this_.soc_power_option);
				this_.soc_power_chart.resize();
				setTimeout(() => {
					window.onresize = function() {
						this_.soc_power_chart.resize();
					}
				}, 500);
			},
			searchFn() {
				var this_ = this;
				this_.reflush();
			},
			exportExcel(url, options = {}, name) {
				return new Promise((resolve, reject) => {
					axios({
						method: 'post',
						url: url, // 请求地址
						data: options, // 参数
						responseType: 'blob' // 表明返回服务器返回的数据类型
					}).then(
						response => {
							resolve(response.data)
							let blob = new Blob([response.data], {
								type: 'application/vnd.ms-excel'
							})
							var fileName = name;
							if(response.headers['content-disposition'].endsWith('.xls"')) {
								fileName = fileName + '.xls';
							} else if(response.headers['content-disposition'].endsWith('.zip"')) {
								fileName = fileName + '.zip';
							}
							if(window.navigator.msSaveOrOpenBlob) {
								navigator.msSaveBlob(blob, fileName)
							} else {
								var link = document.createElement('a')
								link.href = window.URL.createObjectURL(blob)
								link.download = fileName
								link.click()
								//释放内存
								window.URL.revokeObjectURL(link.href)
							}
						},
						err => {
							if(error.message != '') {
								this_.$message.error(error.message, 2, function() {});
								return;
							}
						}
					)
				})
			},
			exportFn() {
				var this_ = this;
				var data = {
					stationId: this.search.stationId,
					equipmentId: this.search.equipmentId,
					signalId: this.search.signalId,
				};
				if(this.search.startTime != null && this.search.endTime != null && this.search.startTime.toDate().getTime() >this.search.endTime.toDate().getTime()) {
					this.$message.error('结束时间早于起始时间，请重新设置查询时间！');
					return;
				} else {
					var dataTime = '';
					if(this_.search.startTime != null){
						dataTime = this_.search.startTime.format('YYYY-MM-DD ') + "00:00:00";
						data.startTime = dataTime;
					} else {
						data.startTime = '1970-01-01 00:00:00'
					}
					if(this_.search.endTime != null){
						dataTime = this_.search.endTime.format('YYYY-MM-DD ') + "23:59:59";
						data.endTime = dataTime;
					} else {
						if(this_.search.startTime.toDate().getTime() > new Date().getTime()){
						} else {
							data.endTime = moment().format('YYYY-MM-DD ') + "23:59:59";
						}
					}
				}
				var now = new Date();
				const url = '/emind/history/data/power_soc_chart/export/' + now.getTime();
				this_.exportExcel(url, data, '功率电量-' + now.Format("yyyy-MM-dd_hh-mm-ss"));
			}
		}
	}
</script>

<style>
@import '../../assets/css/global.css';
@import "../../assets/css/antd.css";
#soc-power-chart {
	width: 100%;
	margin: auto;
	height: 750px;
	overflow: hidden;
	/* background: #000; */
}
</style>